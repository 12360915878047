// @flow

import React from 'react'
// import { Link } from 'gatsby'
import {
  InfoBox,
  InfoBoxGrid,
  InfoBoxImageModal,
  InfoBoxModal,
  InfoBoxText,
} from 'shared-components'
import ArrowIcon from 'mainframeos-com/src/components/Icons/ArrowIcon'

import './community.scss'
import Layout from '../components/Layout'
import DoveIcon from '../components/DoveIcon'
import POB1 from '../images/community/proof-of-being-1.png'
import POB2 from '../images/community/proof-of-being-2.png'
import POB3 from '../images/community/proof-of-being-3.png'
import POB4 from '../images/community/proof-of-being-4.png'
import POB5 from '../images/community/proof-of-being-5.png'
import POB6 from '../images/community/proof-of-being-6.png'
import POB7 from '../images/community/proof-of-being-7.png'
import POF1 from '../images/community/proof-of-freedom-1.jpg'
import POF2 from '../images/community/proof-of-freedom-2.png'
import POF3 from '../images/community/proof-of-freedom-3.png'
import POF4 from '../images/community/proof-of-freedom-4.png'
import POF5 from '../images/community/proof-of-freedom-5.png'
import POF6 from '../images/community/proof-of-freedom-6.png'
import POF7 from '../images/community/proof-of-freedom-7.png'

const CommunityPage = () => (
  <Layout useContainer={false}>
    <div className="community-page">
      <section className="container session-outwards-bottom">
        <h1 className="title">
          Join the <br /> Movement
        </h1>
        <p className="subtitle">
          Data is power. Privacy is power. <br />
          With Mainframe OS, it’s rightfully yours.
        </p>

        <InfoBoxGrid>
          <InfoBox>
            <InfoBoxText>
              <h3>A New Dawn</h3>
              <p>
                Humankind’s yearning for freedom transcends borders, culture,
                and time. We travelled the world asking: What does Mainframe
                mean to you? The response was overwhelming.
              </p>
              <a
                href="https://blog.mainframe.com/the-first-ever-physical-airdrops-b22f13f3457a"
                target="_blank"
                rel="noopener noreferrer"
                className="text-pink text-uppercase">
                Get involved <ArrowIcon className="ml-2 vertical-align-sub" />
              </a>
            </InfoBoxText>
            <InfoBoxImageModal src={POB1} />
          </InfoBox>

          <InfoBox>
            <InfoBoxImageModal src={POB2} />
            <InfoBoxImageModal src={POB3} />
          </InfoBox>

          <InfoBox>
            <InfoBoxImageModal src={POB4} />
            <InfoBoxImageModal src={POB5} />
          </InfoBox>

          <InfoBox>
            <InfoBoxImageModal src={POB6} />
            <InfoBoxImageModal src={POB7} />
          </InfoBox>
        </InfoBoxGrid>
      </section>

      <section className="grey-bg session-inwards">
        <DoveIcon className="dove" />
      </section>

      <section className="container session-outwards-top">
        <InfoBoxGrid>
          <InfoBox>
            <InfoBoxText>
              <h3>Proof of Freedom</h3>
              <p>
                We were blown away by the quality and creativity in the
                submissions for Phase 2. #MainframeForFreedom became a rallying
                cry for those passionate about privacy and freedom.
              </p>
              {/*<Link to="/freedom" className="text-pink text-uppercase">*/}
              {/*  See more <ArrowIcon className="ml-2 vertical-align-sub" />*/}
              {/*</Link>*/}
            </InfoBoxText>
            <InfoBoxModal thumb={POF1}>
              <iframe
                title="soundcloud-player"
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url='https%3A%2F%2Fsoundcloud.com%2Fuser-712576132%2Fmainframe-i-can&amp;auto_play=true&amp;show_artwork=true&amp;color=0066cc"
              />
            </InfoBoxModal>
          </InfoBox>

          <InfoBox>
            <InfoBoxImageModal src={POF2} />
            <InfoBoxImageModal src={POF3} />
          </InfoBox>

          <InfoBox>
            <InfoBoxImageModal src={POF4} />
            <InfoBoxImageModal src={POF5} />
          </InfoBox>

          <InfoBox>
            <InfoBoxImageModal src={POF6} />
            <InfoBoxImageModal src={POF7} />
          </InfoBox>
        </InfoBoxGrid>
      </section>
    </div>
  </Layout>
)

export default CommunityPage
