import React from 'react'

const ArrowIcon = props => (
  <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.793.234a.81.81 0 0 0 0 1.131L14.014 7.7H.786A.793.793 0 0 0 0 8.5c0 .442.352.8.786.8h13.228l-6.221 6.334a.813.813 0 0 0 0 1.133.776.776 0 0 0 1.11 0l7.564-7.7a.808.808 0 0 0 .163-.253l.007-.008v-.002a.818.818 0 0 0 .001-.606.844.844 0 0 0-.171-.264L8.904.234a.776.776 0 0 0-1.111 0"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export default ArrowIcon
